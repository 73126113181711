.cardImg {
  width: 128px;
  height: 128px;
}
.card {
  width: 128px;
  /* height: 192px; */
  border: 1px solid #dee2e6;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.cardText {
  margin: 0;
  padding: 0 5px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 500;
}

@media screen and (max-width: 1085px) {
  .card {
    width: 112px;
  }
  .cardImg {
    width: 128px ;
    height: 128px ;
  }
}

/* @media screen and (max-width: 1006px) {
    .card {
      width: 112px;
    }
    .cardImg {
      width: 128px ;
      height: 128px ;
    }
  } */
