.announcement {
    width: 18px;
    height: 18px;
}

.banner {
    max-width: 100%;
    margin: auto !important;
    /* border-bottom: 2px solid #F5F5F5; */
}

.rightBanner {
    display: flex;
}

.bannerText{
    font-size: 14px;
}
@media screen and (max-width: 992px) {
    .rightBanner {
        display: none;
    }
    .bannerText {
        justify-content: center;
    }
}
@media screen and (min-width: 992px ) {
   .banner{
    border-bottom: 2px solid #F5F5F5;

   }
}

@media screen and (max-width: 576px) {
    .bannerText{
        font-size: 12px;
    }
    
}

@media screen and (max-width: 768px ) {
    
    .bannerText{
        font-size: 13px;
    }
}