.host{
    background:#FFF;
    width:50px;
    margin: auto;
    padding-top:40px;
  
  }
  .loading{
    width:10px;
    height:10px;
    background:#FFF;
    border-radius:100%;
    float:left;
    margin-right:5px;
  }
  .loading_0{
      -webkit-animation-delay:.1s;
      background:#E38AFB
    
  }
  .loading_1{
      -webkit-animation-delay:.2s;
      background: #d870f5 !important
  }
  .loading_2{
      -webkit-animation-delay:.3s;
      background:#c74ee8;
  }
  @-webkit-keyframes bounce {
    0%, 100% {
      opacity:1 ;
    }
    60% {
      opacity:.0;
     
    }
  }