/* sidebar */
.navbarToggleExternalContent{
    transform: translateX(-100%);
    transition: transform .35s ease;
    display: block;
    height: 100%;
    min-height: 100%;
    z-index: 2;
    width: 100%;
  }
  
  .navbarToggleExternalContent.menu-show{
    transform: translateX(0%);
  }
 .profile_div{
  background-color: #100146 ;
 }
.nav_mb{
  /* border-top: 2px solid #F5F5F5; */
    border-bottom: 2px solid #F5F5F5;
    font-size: 18px;
    font-weight: 400;


}

.stock_span{
color: #686E76;
}
.nav_header{
  border-radius: 0 !important;
}
.nav_header :global( .collapsed )::after {
  /* content: url(../../public/icons/chevrondown.svg) !important; */
  /* background-image: url(../../public/icons/chevronInactivedown.svg) !important; */
  background-size: 78% !important;

  background-image: url(../../public/icons/chevrondown.svg) !important;

}
.nav_header :global(.accordion-button):not(.collapsed)
{
  background-color: #EDF2FA;
  border-radius: 0 !important;
  color: #39414A;
font-size: 14px !important; 
display: flex;
align-items: flex-end;



}
.nav_header :global(.accordion-button):focus{
  box-shadow: none;
  color: #5932EA !important;
}
.nav_header :global(.accordion-button):hover{
color: #39414A;  
font-size: 20px !important; 
}
.nav_header :global(.accordion-button):not( :global(.collapsed) )::after
{
  background-image: url(../../public/icons/chevronInactive.svg) !important;
  background-size: 64% !important;
  transform: none !important;


}
.bottom_div{
  height: 70px; 
  position: fixed; 
  bottom:0%;
  width:100%; 
  background-color: white; 
  opacity: 1;
  z-index: 2;
}

.accordianBodyContainer{
  height: 30vh;
  overflow: auto;
}


.accordianBodyContainer::-webkit-scrollbar {
  width: 0.3em;
}

.accordianBodyContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.accordianBodyContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}

.specsCentralSidebar{
  color: #212529;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-radius: 0 0 0.3rem 0.3rem;
  background-color: #EDF2FA;
}
.specsCentralSidebar h2{
  padding: 0;
  margin: 0;
}

.specsCentralSidebar button{
  position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    background-color: #EDF2FA;
    font-weight: 500 !important;
    font-size: 20px !important;
}