.related_div{
    padding-top: 100px;
    padding-bottom: 100px;
}
.product_title {
    overflow: hidden;
    display: -webkit-box;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.3;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
    cursor: pointer ;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .product_title:hover{
     color: #d957f9;;
  }
@media screen and (min-width: 1601px)
{
.related_div :global(.main){
    margin: 0px 321px;
}
}
@media screen and (max-width: 1024px)
{
.related_div :global(.main){
    margin: 0px 10px;
}
}
.re_h1{
    font-size: 24px;
}
.re_paragraph_div{
    display: flex;
    justify-content: center;
}
.re_h1{
    order: 1;
    display: flex !important;
    margin-left: auto; 

}

.arrows{
    order: 2; /* places this element second */
    margin-left: auto; 
}
.favarrow{
    background: #F5F6FA;
}
.slider_arrow{
    position: absolute;
    height: 75%;
    width: 100%;
    /* top: 148%; */
}
.arrow_btn_div {
    top: 6%;
    z-index: 1;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .arrow_btn_div_right {
    top: 6%;
    z-index: 1;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  
  .arrow_btn_btn {
    opacity: .6;
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 0px !important;
    height: 40px;
  }
  .re_link{
    color:#5932EA;
    text-decoration: none;
  }
@media screen and (max-width: 992px) {
    .slider_div{
    width: 300;
    }
    .related_div :global(.main){
        margin: 0px 0px !important;
    }
    .re_h1{
        margin-left: 0px;
    }
    }
 
@media screen and (max-width: 500px) {
    .re_h1{
        font-size: 18px;
    }
    .para{
        font-size: 14px;
    }
    .slider_arrow{
        height: 60%;
        /* top: 148%; */
    }
    .arrow_btn_div{
        width: 48px;
    }
    .arrow_btn_div_right{
        width: 48px;
    }
}
