/* Styles Added */

.navContainer{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}


.navButtonBootstrap:global(.btn-primary){
    background: transparent !important;
}
.navButtonBootstrap:global(.btn):focus-visible{
    box-shadow: none !important;
}
.navButtonBootstrap:global(.btn[aria-expanded="true"]) > :local(.closeNavButton){
    display: none !important;
}
.navButtonBootstrap:global(.btn[aria-expanded="false"]) > :local(.closeNavButton){
    display: block !important;
}
.navButtonBootstrap:global(.btn[aria-expanded="true"]) :local(.openNavButton){
    display: block;
}
.navButtonBootstrap:global(.btn[aria-expanded="false"]) :local(.openNavButton){
    display: none;
}

/* .navButtonBootstrap:global(.btn[aria-expanded="true"]) .closeNavButton{
    display: block;
}
.navButtonBootstrap:global(.btn[aria-expanded="false"]) .openNavButton{
    display: block;
} */

.navButtonBootstrap{
   height: 35px;
}
.navButtonBootstrap:global(.dropdown-toggle)::after{
    content: "" !important;
    vertical-align: 0 !important;
}

/* .navMenu{
    cursor: pointer;
    background: white;
    width: 200px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
} */

.navMenu :global(.dropdown-item){
    padding:10px 8px !important;
    height: 49px !important;

}
.navMenu :global(.dropdown-item):hover{
    color: rgba(89, 50, 234, 1);
    background: #EDF2FA
}
.navMenu{
    transform: translate(-186px,35px) !important;
    width: 220px !important;
}

.navMenu:global(.dropdown-menu){
    --bs-dropdown-padding-y: 0 !important;
    --bs-dropdown-divider-margin-y: 0  !important;
}

.navMenuOption{
    font-weight: bold;
    color: #39414A !important;
}

.searchBox [type="search"]{
    outline:none !important;
    margin-left: 35px;
}

.searchBox{
    height: 50px;
}

.searchIconDiv{
    height: 50px;
}

.searchButton{
    border-radius: 0 0.3rem 0.3rem 0 !important;
}
/*
NAV STYLES 
*/

.subNavContainer{
    z-index: 1000;
    top:0;
    margin-left: 211px;
    height: 100%;
    background: white;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
}

.navitem {
    box-shadow: 0px 0px 8px #0a141e21 !important;
    display: grid;
    border: none !important;
    height: 100% !important;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 10px;
    grid-auto-columns: 135px;
    padding-left: 10px !important;
    padding-right: 5px;
    
}

.navitem a:not(.disabledItem){
    font-size: 14px;
    font-weight: 600 !important;
    color: #39414A !important;  
}
.navitem a.disabledItem{
    --bs-text-opacity: 1;
    color: #6c757d !important;
    cursor: default;
}

.navitem a:not(.disabledItem):hover{
    color: rgba(89, 50, 234, 1) !important;  
    background: #EDF2FA !important;
}



.nav_content{
    padding: 10px 15px !important;
    width: 135px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.nav_border{
    border-bottom: 1px solid #DEDFE4 !important;
}

.iconDropdown:global(.btn-primary){
    background: transparent !important;
}
.iconDropdown:global(.btn):focus-visible{
    box-shadow: none !important;
}

.iconDropdown:global(.dropdown-toggle)::after{
    content: "" !important;
    vertical-align: 0 !important;
}
.iconDropdown{
    height: 100%;
    border-left: 1px solid rgba(0,0,0,0.2) !important;
    border-radius: 0 !important;
}
.iconDropdown > img{
    height: 100%;
    width: 100%;
}
.iconDropdown > .badge{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 5px;
    height: 5px;
    background-color: purple;
    border-radius: 50%;
    display: block,
}

.suggestionValues{
    padding-left: 10px;
}
.highlighted{
    color: #5932EA;
    background: #EDF2FA;}
/* .suggestionValues:hover{
   
} */

@media screen and (max-width: 768px)
{
 
    .iconDropdown > img{
        height: 100% !important;
        width: 100% !important;
    }
    .searchBox{
        height: 40px;
    }
    .searchIconDiv{
        height: 40px;
    }
    .iconDropdown{
        padding:5px;
        width: 28px;
    }
}

/*END Styles Added*/


/*Home nav*/


.navrow{
    flex-grow: 0 !important;
}

.homeNavitem {
    margin-left: 1rem !important; 
}

.homeNavitem a{
    font-size: 17px;
    font-weight: 600 !important;
    color: #39414A !important;  
}

.navitemActive :global(.show):focus{
    font-size: 17px;
    font-weight: 600 !important;
    color: #D957F9 !important;
    
 }

 .navitemActivespecs :global(.show):focus{
    font-size: 17px;
    font-weight: 600 !important;
    color: #D957F9 !important;
    
 }
 .navitemActive :global(.nav-link):hover{
    font-size: 17px;
    font-weight: 600 !important;
    color: #D957F9 !important;
 }

 .navitemActive :global( .show):focus::after{
    content: url(../../public/icons/chevronup.svg) !important;
  border: none !important;
  vertical-align: 0.215em !important;
  font-size: 17px;
  font-weight: 600 !important;
  color: #D957F9 !important;
 }
 .navitemActivespecs :global( .show):focus::after{
    content: "" !important;
  border: none !important;
  vertical-align: 0.215em !important;
  font-size: 17px;
  font-weight: 600 !important;
  color: #D957F9 !important;
 }

 .nav_content span{
    font-size: 14px;
}

.homeNavitem > :global(.dropdown-menu) {
    box-shadow: 0px 0px 8px #0a141e21 !important;
    /* display: grid; */
    border: none !important;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    column-gap: 10px;
    grid-auto-columns: 135px;
    padding-left: 10px !important;
    padding-right: 5px;
}

.specs_central{
    color: #39414A !important;  
    font-weight: 600;
    padding-bottom: 3px;
}
.specs_central:hover{
    border-bottom: 2px solid;
    /* border-color: #39414A !important; */
    color: #D957F9 !important;
}

.homeNavitem > div[aria-labelledby="NavItemLink0"] {
    left: auto;
}

.homeNavitem > div[aria-labelledby="NavItemLink1"] {
    left: auto;
}
.homeNavitem > div[aria-labelledby="NavItemLink2"] {
    left: auto;
}
.homeNavitem > div[aria-labelledby="NavItemLink3"] {
    left: auto;;
}
.homeNavitem > div[aria-labelledby="NavItemLink4"] {
    left: auto;
    /* right: auto  ; */
    right: 0px;
}
.homeNavitem > div[aria-labelledby="NavItemLink5"] {
    left: auto;
    right: 0px;
}


@media screen and (max-width: 1250px)
{
    .iconDropdown > img{
        height: 110%;
        width: 110%;
    }
    .homeNavitem a{
        font-size: 14px;
    }
    .homeNavitem a:hover{
        font-size: 14px !important;
    }
    .homeNavitem {
        margin-left: -0.25rem !important; 
    }
}

.disabledNavItem{
    cursor: default;
    opacity: 0.7;
}

.disabledNavItem:hover{
    background-color: white;
}

.navViewAllOption{
    color: var(--active-color) !important;
}





/*END Home nav*/

@media screen and (max-width: 576px) {
    .tblogo{
        width: 24px;
        height: 24px;
    }
}
